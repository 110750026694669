import React, { useEffect } from 'react';

import RemotePortal from './RemotePortal';
import '../externalRefs';
import { useProjectConfig } from '../hooks';
import { getPackageUrl } from '../utils';

function Basement({ env }) {
  const {
    fetchedError,
    configs: {
      currentVersion = '0.1.0',
      versionConfig = {},
      projectConfig: config,
      entrypoint,
    },
    loadVersionConfig,
    loadProjectConfig,
  } = useProjectConfig();

  // load base.config.json
  useEffect(() => {
    loadVersionConfig();
    // eslint-disable-next-line
  }, []);

  if (config) {
    if (config.portal && config.portal.name) {
      const { name, version } = config.packages[config.portal.name];
      const versionName =
        version && version !== '0.1.0' ? `${name}-v${version}` : name;

      const remotePortalProps = {
        env: { ...env, REACT_APP_ROUTE_BASE: entrypoint },
        source: getPackageUrl(versionName),
        // mainly from base.config.json
        versionConfig,
        // mainly from project.config-v*json
        config,
        // value * of project.config-v*.json
        currentVersion,
        updateConfig: loadProjectConfig,
        updateEntryPoint: loadVersionConfig,
      };

      return <RemotePortal {...remotePortalProps} />;
    }
  }

  if (fetchedError) {
    return (
      <div className="error">
        Request was unsuccessful because of an unexpected condition encountered
        by the server. Please try again later. For assistance, please contact
        our Customer Service Representatives on (852) 2198 8000.
      </div>
    );
  }

  return <div className="loading" />;
}

export default Basement;
