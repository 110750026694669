require("react-app-polyfill/ie11");
require("react-app-polyfill/stable");
require("systemjs/dist/system");
require("systemjs/dist/extras/amd");
require("systemjs/dist/extras/named-exports");
require("systemjs/dist/extras/transform");
require("systemjs-webpack-chunk-interop");

var imports = {
"react": function() {return import("react");},
"react-dom": function() {return import("react-dom");},
"react/jsx-runtime": function() {return import("react/jsx-runtime");},
"@jsonforms/core": function() {return import("@jsonforms/core");},
"@jsonforms/react": function() {return import("@jsonforms/react");},
"@reduxjs/toolkit": function() {return import("@reduxjs/toolkit");},
"axios": function() {return import("axios");},
"connected-react-router": function() {return import("connected-react-router");},
"helmet": function() {return import("helmet");},
"history": function() {return import("history");},
"hoist-non-react-statics": function() {return import("hoist-non-react-statics");},
"intl": function() {return import("intl");},
"invariant": function() {return import("invariant");},
"lodash": function() {return import("lodash");},
"lodash/pick": function() {return import("lodash/pick");},
"lodash/get": function() {return import("lodash/get");},
"lodash/isEmpty": function() {return import("lodash/isEmpty");},
"lodash/cloneDeep": function() {return import("lodash/cloneDeep");},
"lodash/isEqual": function() {return import("lodash/isEqual");},
"lodash/isEqual": function() {return import("lodash/isEqual");},
"lodash/sortBy": function() {return import("lodash/sortBy");},
"lodash/uniq": function() {return import("lodash/uniq");},
"lodash/uniqWith": function() {return import("lodash/uniqWith");},
"lodash/defaults": function() {return import("lodash/defaults");},
"lodash/intersectionWith": function() {return import("lodash/intersectionWith");},
"lodash/isPlainObject": function() {return import("lodash/isPlainObject");},
"lodash/isBoolean": function() {return import("lodash/isBoolean");},
"lodash/defaultsDeep": function() {return import("lodash/defaultsDeep");},
"lodash/flatten": function() {return import("lodash/flatten");},
"lodash/flattenDeep": function() {return import("lodash/flattenDeep");},
"lodash/intersection": function() {return import("lodash/intersection");},
"lodash/pullAll": function() {return import("lodash/pullAll");},
"lodash/forEach": function() {return import("lodash/forEach");},
"lodash/without": function() {return import("lodash/without");},
"lodash/union": function() {return import("lodash/union");},
"lodash/toPath": function() {return import("lodash/toPath");},
"moment": function() {return import("moment");},
"react-collapse": function() {return import("react-collapse");},
"react-helmet-async": function() {return import("react-helmet-async");},
"react-intl": function() {return import("react-intl");},
"react-redux": function() {return import("react-redux");},
"react-router-dom": function() {return import("react-router-dom");},
"react-side-effect": function() {return import("react-side-effect");},
"react-svg": function() {return import("react-svg");},
"react-inlinesvg": function() {return import("react-inlinesvg");},
"redux": function() {return import("redux");},
"redux-saga": function() {return import("redux-saga");},
"redux-saga/effects": function() {return import("redux-saga/effects");},
"reselect": function() {return import("reselect");},
"sanitize.css": function() {return import("sanitize.css");},
"sanitize.css/sanitize.css": function() {return import("sanitize.css/sanitize.css");},
"styled-components": function() {return import("styled-components");},
"styled-system": function() {return import("styled-system");},
"validator": function() {return import("validator");},
"react-hook-form": function() {return import("react-hook-form");},
"system-js": function() {return import("system-js");}
};

window.System.appendImportMap(imports);
