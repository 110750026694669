/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.warn(error, errorInfo);
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="error">
          Request was unsuccessful because of an unexpected condition
          encountered by the server. Please try again later. For assistance,
          please contact our Customer Service Representatives on (852) 2198
          8000.
        </div>
      );
      // return <FormattedMessage id="components.Portal.error_500" />;
    }
    return this.props.children;
  }
}
