import { useState, useCallback } from 'react';
import SystemJS from 'system-js';
import {
  getRuntimeConfig,
  makeVersionControlledConfig,
  convertPackageListToImportMap,
} from '../utils';

const routeBase = JSON.parse(process.env.REACT_APP_ROUTE_BASE);

export const useProjectConfig = () => {
  const [configs, setConfigs] = useState({
    projectConfig: null,
    versionConfig: {},
    currentVersion: '0.1.0',
    entrypoint: routeBase.default,
  });

  const [fetchedError, setError] = useState(null);

  /**
   * @description update config
   * @param {string} version v0.1.0, v0.2.0
   */
  const loadProjectConfig = useCallback(
    (version, vc) => {
      const { entryPoint: _entryPoint, runtimeConfigPath } = getRuntimeConfig();

      const runtimeProjectConfigPath = `${runtimeConfigPath}/project.config-v${version}.json`;

      // fetch current project.config-v*.json
      fetch(`${runtimeProjectConfigPath}?t=${Date.now()}`)
        .then(res => res.json())
        .then(loadedConfig => {
          const versionControlledConfig = makeVersionControlledConfig(
            loadedConfig,
          );
          // components: all static folder
          // packages: from config repo
          const { components = {}, packages = {} } = versionControlledConfig;
          const packagesMap = convertPackageListToImportMap(packages);
          SystemJS.appendImportMap({ ...components, ...packagesMap });

          setConfigs({
            versionConfig: vc ?? configs.versionConfig,
            currentVersion: version,
            entrypoint: _entryPoint,
            projectConfig: versionControlledConfig,
          });
          // setCurrentVersion(version);
          // setEntrypoint(_entryPoint);
          // setProjectConfig(versionControlledConfig);
        })
        .catch(error => {
          console.warn('[ProjectConfig] error=%o', error);
          setError(error);
        });
    },
    [configs.versionConfig],
  );

  /**
   * update version
   */
  const loadVersionConfig = useCallback(() => {
    const { runtimeConfigPath } = getRuntimeConfig();

    const runtimeBaseConfigPath = `${runtimeConfigPath}/base.config.json`;

    // fetch runtime base.config.json
    fetch(`${runtimeBaseConfigPath}?t=${Date.now()}`)
      .then(res => res.json())
      .then(vc => {
        // vc eg: {/ebanking-amd-form: '0.1.0', /login: '0.2.0', /applications: '0.2.0'}
        const { pathname } = window.location;

        const _currentVersion =
          vc[
            Object.keys(vc).find(buisnessRootPath =>
              pathname.includes(buisnessRootPath),
            )
          ] || '0.2.0';

        loadProjectConfig(_currentVersion, vc);
      })
      .catch(error => {
        console.warn('[BaseConfig] error=%o', error);
        setError(error);
      });
  }, [loadProjectConfig]);

  return {
    fetchedError,
    configs,
    loadVersionConfig,
    loadProjectConfig,
  };
};
