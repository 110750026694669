import 'sanitize.css/sanitize.css';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import Basement from './containers/Basement';

import './css/font-awesome.min.css';
import './css/font-roboto.css';
import './css/icomoon.css';

const globalEnvNames = Object.keys(process.env).filter(
  name => name === 'NODE_ENV' || name.startsWith('REACT_APP_'),
);
const globalEnvValues = {};
for (const name of globalEnvNames) {
  globalEnvValues[name] = process.env[name];
}

export const MOUNT_NODE = document.getElementById('main');
const render = () => {
  if (MOUNT_NODE) {
    ReactDOM.render(<Basement env={globalEnvValues} />, MOUNT_NODE);
  }
};

export const start = () => {
  render();
  if (module.hot) {
    // Hot reloadable React components and translation json files
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept(['./containers/Basement'], () => {
      ReactDOM.unmountComponentAtNode(MOUNT_NODE);
      render();
    });
  }
};
start();
