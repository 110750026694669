import React from 'react';
import SystemJS from 'system-js';
import ErrorBoundary from './ErrorBoundary';

function RemotePortal ({ source, ...rest }) {
  const LazyComp = React.lazy(() => SystemJS.import(source));

  return (
    <React.Suspense fallback={<div className="loading" />}>
      <ErrorBoundary>
        <LazyComp {...rest} mode={process.env.NODE_ENV} />
      </ErrorBoundary>
    </React.Suspense>
  );
};

export default RemotePortal;
