import { isSemVer } from 'validator';

const jsSuffix = 'static/js/main.js';
const routeBase = JSON.parse(process.env.REACT_APP_ROUTE_BASE);
const projectConfigPath = JSON.parse(process.env.REACT_APP_PROJECT_CONFIG_PATH);

const getSemVersionPath = (prefix, version) => {
  return version && version !== '0.1.0' && isSemVer(version)
    ? `${prefix}-v${version}`
    : prefix;
};

const makeVersion = version => {
  return version === '0.1.0' ? '' : version;
};

export const getRemoteUrl = (
  relativePath,
  secondRelativePath = '',
  version,
) => {
  let rootPath = `${process.env.REACT_APP_ROOT_PATH}/${relativePath}`;

  if (
    process.env.NODE_ENV !== 'development' &&
    !relativePath.endsWith('pilot') &&
    !relativePath.includes('-mfe')
  ) {
    let prefix = `${rootPath}-mfe`;
    let appendVer = version;

    const suffix = relativePath.substr(-7);
    const realVer = suffix.substr(2);
    if (suffix.startsWith('-v') && isSemVer(realVer)) {
      prefix = `${rootPath.split(suffix)[0]}-mfe`;
      appendVer = realVer;
    }

    rootPath = getSemVersionPath(prefix, appendVer);
  }

  if (process.env.NODE_ENV === 'development') {
    rootPath = `/widgets/${getSemVersionPath(relativePath, version)}/build`;
  }

  if (secondRelativePath) {
    rootPath = `${rootPath}/${secondRelativePath}`;
  }

  return rootPath;
};

export const getPackageUrl = (packageName, subpath = jsSuffix, version) => {
  return getRemoteUrl(packageName, subpath, version);
};

export const convertPackageListToImportMap = (packages = {}) => {
  const map = {};
  const packageNamespaces = Object.keys(packages);
  for (const packageNamespace of packageNamespaces) {
    const { name, version } = packages[packageNamespace];
    const path = getPackageUrl(name, jsSuffix, version);
    map[name] = path;
  }
  return map;
};

/**
 * @returns {object}
 * {string} entryPoint: '/portalserver/hase-cos'
 * {string} runtimeConfigPath: '/devconfig/hase-cos-portal-config'
 */
export const getRuntimeConfig = () => {
  const browserPathName = window.location.pathname;

  const routeBaseArr = Object.entries(routeBase).sort((a, b) => {
    return a.length > b.length ? 1 : -1;
  });

  // mapping with length sorted paths.
  for (const rb of routeBaseArr) {
    const runtimeConfig = {
      entryPoint: routeBase.default,
      runtimeConfigPath: projectConfigPath.default.path,
    };

    if (browserPathName.includes(rb[1])) {
      const key = rb[0];
      runtimeConfig.entryPoint = routeBase[key];
      runtimeConfig.runtimeConfigPath = projectConfigPath[key].path;
    }

    return runtimeConfig;
  }
};

/**
 * refactor name witout version, for normal import use
 */
const refactorConfigName = (reduceArr, initData) => {
  return reduceArr.reduce((newComp, { name, version }) => {
    const _newComp = { ...newComp };
    const versionName = getSemVersionPath(name, version);

    _newComp[name] = _newComp[versionName];
    if (version && version !== '0.1.0') {
      delete _newComp[versionName];
    }

    return _newComp;
  }, initData);
};

export const makeVersionControlledConfig = config => {
  const packagesValueArr = Object.values(config.packages);
  const { version } = packagesValueArr.find(
    ({ name }) => name === config.portal.name,
  );

  const base = config.base.map(({ name: baseName }) => {
    const exactInfo = packagesValueArr.find(({ name }) => name === baseName);
    return {
      name: baseName,
      version: makeVersion(exactInfo.version),
    };
  });

  const _components = refactorConfigName(packagesValueArr, config.components);
  const components = refactorConfigName(config.routes, _components);

  return {
    ...config,
    portal: {
      name: config.portal.name,
      version: makeVersion(version),
    },
    base,
    components,
  };
};
